import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import "../../i18n";

/* components */
import { ReactComponent as ArrowLeft } from '@svg/arrowLeft.svg';

/* styles */
import style from '@styles/pdp/Header.module.scss';

export default function Header({preview}){
    const { t } = useTranslation("translation");
    const url = '/' + (preview ? '?preview=true' : '')
    return (
        <div className={style.container}>
            <Link to={url} className={style.cta}>
                <ArrowLeft />
                <span> {t("pdp.BACK")}</span>
            </Link>
        </div>
    );
}