import { createContext } from "react"

const context = createContext()

export const initialContext = {
    bundles: [],
    bundleGroup: [],
    collectionGroup: [],
    filters: {
        all: {},
        active: {},
        result: []
    },
    loading: true,
    sizesPopup: false
}

export default context