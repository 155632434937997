import { useContext } from 'react';

/* styles */
import style from '@styles/pdp/ColorPicker.module.scss';

/* context */
import context from '@context/pdp';
import { setVariant } from '@context/pdp/actions';

/* utils */
import { getColorPickerThumbs } from '@utils/detail';
// import { colorVariantChange } from '@utils/analytics';


export default function ColorPicker() {
    const { state, dispatch } = useContext(context);
    const colors = state.step.product.colors

    const getItemClassName = (variant) => {
        const stepProduct = state.step.product
        const bundleProduct = state.bundle.products[state.step.index]
        let className = style.colorPickerItem

        if (stepProduct.variant.code === variant) {
            className += ` ${style.colorPickerItemSelected}`
        } else {
            if (bundleProduct.variant) return style.colorPickerItemHidden
        }

        return className
    }

    const handleClick = (color) => {
        setVariant({ state, dispatch }, state.step.product.partNumber, color)
        // colorVariantChange(state.step.product.partNumber, color.label)
    }

    return <div className={style.colorPicker}>

        {colors.map((color) => <div key={color.code} className={getItemClassName(color.code)} onClick={() => handleClick(color)}>
            <img src={getColorPickerThumbs(state.step.product.partNumber, color.code)} alt={color.code} />
        </div>)}
    </div>
}