import { useContext } from "react"
import { ReactComponent as Ruler } from '@svg/ruler.svg';
import { useTranslation } from "react-i18next";
import "../../i18n";

/* styles */
import style from "@styles/pdp/Info.module.scss"

/* components */
import Price from "@components/pdp/Price"
import ColorPicker from "@components/pdp/ColorPicker"
import SizePicker from "@components/pdp/SizePicker"
import InseamPicker from "@components/pdp/InseamPicker"
import CollapsibleInfo from "@components/pdp/CollapsibleInfo"
import Gallery from "@components/pdp/Gallery"
import SizeAlert from "@components/pdp/SizeAlert"

/* context */
import context from "@context/pdp"
import { setStep, setSizeAlert, addBundleToCart, toggleSizesPopup } from '@context/pdp/actions';

/* utils */
import { decodeEntities, getDiscountPrice, formatPrice } from "@utils/helpers"
import { onGoToNextProduct } from "@utils/analytics";
import { formatSize } from "@utils/detail"

export default function Info() {
    const { t } = useTranslation("translation");
    const { state, dispatch } = useContext(context);

    const GetLabel = (value) => {
        if(value === 'REG') {
            return(t('size-mapping.regular'))
        } else if (value === 'SHT') {
            return(t('size-mapping.short'))
        } else if (value === 'OS') {
            return(t('size-mapping.one-size'))
        } else {
            return value
        }
    }
    const size = state.bundleConfiguration[state.step.product.partNumber]?.size
    const inseam = GetLabel(state.bundleConfiguration[state.step.product.partNumber]?.inseam, state)
    const stockLeft = state.mouseoverSize?.stockLeft ? state.mouseoverSize.stockLeft : state.bundleConfiguration[state.step.product.partNumber]?.stockLeft

    const handleProceed = () => {
        if (state.bundleConfiguration[state.step.product.partNumber]?.size) {
            onGoToNextProduct(state.bundle.name, state.bundle.price, state.bundle.discount, state.step.nextSteps[0])
            setStep({ state, dispatch }, state.step.nextSteps[0])
        } else {
            setSizeAlert({ dispatch }, true)
            setTimeout(() => setSizeAlert({ dispatch }, false), 2000);
        }
    }

    const handleAddToCart = () => {
        if (state.bundleConfiguration[state.step.product.partNumber]?.size) {
            addBundleToCart({ state, dispatch })
        } else {
            setSizeAlert({ dispatch }, true)
            setTimeout(() => setSizeAlert({ dispatch }, false), 2000);
        }
    }

    const getCtaClassName = () => {
        let className = style.cta
        if (state.step.product.isOOS) {
            className += ` ${style.ctaOOS}`;
        } else {
          if (state.step.nextSteps.length === 0) {
            className += ` ${style.ctaAddToCart}`;
            if (state.step.configured !== state.bundle.products.length) {
              className += ` ${style.ctaDisabled}`;
            }
          }
        }
        return className
    }

    return (
      <div className={style.container}>
        <div className={style.wrapper}>
          <h1 className={style.name}>
            {decodeEntities(state.step.product.name)}
          </h1>

          <Price
            listPrice={formatPrice(
              state.step.product.finalPrice
            )}
            offerPrice={getDiscountPrice(
              state.step.product.finalPrice,
              state.bundle.discount
            )}
          />
        </div>

        <Gallery mobile />

        <div className={style.wrapper}>
          <hr />
          <div className={style.pickerContainer}>
            <div className={style.pickerText}>
              <span className={style.label}>{t("pdp.COLOR")}</span>
              <span className={style.selectedDescription}> : </span>
              <span className={style.selectedDescription}>
                {state.step.product.variant.label}
              </span>
            </div>
            <ColorPicker />
          </div>
          <hr />
          <div className={style.pickerContainer} id="size-picker">
            <div className={style.pickerText}>
              <span className={style.label}>{t("pdp.SIZE")}</span>
              {size ? (
                <>
                  <span className={style.selectedDescription}>
                    : {formatSize(size)}
                  </span>
                </>
              ) : null}
              {(stockLeft <= 5 && !state.step.product.isOOS)&& <span className={style.selectedStockAlert}>{stockLeft === 1 ? t("pdp.LAST-ITEM") : t("pdp.FEW-ITEMS-LEFT")}</span>}
            </div>
            {state.step.sizeAlert && <SizeAlert />}
            <SizePicker />
            {state.step.product.sizeContext && (
              <div className={style.sizeGuide}>
                <button
                  className={style.sizeHelperCTA}
                  onClick={() => toggleSizesPopup({ dispatch })}
                >
                  <Ruler />
                  <span>{t("pdp.SIZE-CHART")}</span>
                </button>
              </div>
            )}
          </div>
          <hr />
          {state.step.product.hasInseam && (
            <>
              <div className={style.pickerContainer}>
                <div className={style.pickerText}>
                  <span className={style.label}>{t("pdp.INSEAM")}</span>
                  {state.bundleConfiguration[state.step.product.partNumber]
                    ?.inseam ? (
                    <>
                      <span className={style.selectedDescription}>
                        : {inseam}
                      </span>
                    </>
                  ) : null}
                </div>
                <InseamPicker />
              </div>
              <hr />
            </>
          )}

          {state.step.product.isOOS ? (
            <button className={getCtaClassName()}>
              {t("pdp.OUT-OF-STOCK")}
            </button>
          ) : state.step.nextSteps.length === 0 ? (
            <button className={getCtaClassName()} onClick={handleAddToCart}>
              {t("pdp.ADD-TO-CART")}
            </button>
          ) : (
            <button className={getCtaClassName()} onClick={handleProceed}>
              {t("pdp.NEXT-PRODUCT")}
            </button>
          )}
          <CollapsibleInfo />
        </div>
      </div>
    );
}