import { createContext } from "react";
import { getStoreKey } from "@utils/detail";

const context = createContext();
export default context


const initialContext = {
    bundleConfiguration: {},
    sliderGallery: false,
    stepBarMinimized: true,
    mouseoverSize: {},
}

export function getInitialContext(bundleId) {
    const storeKey = getStoreKey(bundleId)
    const storedState = JSON.parse(sessionStorage.getItem(storeKey))

    return storedState || initialContext
}
