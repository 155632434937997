/* utils */
import { getPublicBundleList } from "@utils/fetch";
import { serializeProductData } from "@utils/detail";
import { isMobile } from "@utils/helpers";
import { populateMiniCartMobile } from "@utils/addToCartHelper";
import { addToCart, productDetailView } from "@utils/analytics";
import { getDiscountPrice } from "../../utils/helpers";

const actions = {
  GET_BUNDLE: "GET_BUNDLE",
  SET_STEP: "SET_STEP",
  SET_PDP_LOADING: "SET_PDP_LOADING",
  SET_PDP_CART_LOADING: "SET_PDP_CART_LOADING",
  STOP_PDP_CART_LOADING: "STOP_PDP_CART_LOADING",
  SET_PRODUCT_VARIANT: "SET_PRODUCT_VARIANT",
  SET_CHOICE_VARIANT: "SET_CHOICE_VARIANT",
  SET_CHOICE_SIZE: "SET_CHOICE_SIZE",
  SET_CHOICE_INSEAM: "SET_CHOICE_INSEAM",
  SET_STEP_SIZE_ALERT: "SET_STEP_SIZE_ALERT",
  ADD_TO_CART: "ADD_TO_CART",
  TOGGLE_SIZES_POPUP: "TOGGLE_SIZES_POPUP",
  SET_STEP_BAR_MINIMIZED: "SET_STEP_BAR_MINIMIZED",
  TOGGLE_SLIDER_GALLERY: "TOGGLE_SLIDER_GALLERY",
  SET_MOUSEOVER_SIZE: "SET_MOUSEOVER_SIZE",
  REMOVE_MOUSEOVER_SIZE: "REMOVE_MOUSEOVER_SIZE",
  RESET_ADD_TO_CART_COMPLETE: "RESET_ADD_TO_CART_COMPLETE"
};

export default actions;

export function setStep(context, stepIndex = 0) {
  const { state, dispatch } = context;

  if (state.bundle) {
    setLoading(context);
    const bundleProducts = state.bundle.products;

    if (bundleProducts.length > stepIndex) {
      // get current step product data through websphere
      const sku = bundleProducts[stepIndex].sku;

      window.websphere.productViewByStyleNumber(sku, (data) => {
        window.websphere.productAvailabilityViewByProductId(
          data.CatalogEntryView[0].uniqueID,
          (availability) => {
            const urlLangId = document.querySelector('meta[name="langId"]')?.content || "-11";
            const storeId = document.querySelector('meta[name="storeId"]')?.content || "7005";
            const catalogId = document.querySelector('meta[name="catalogId"]')?.content || "13506";
            const domain = window.location.hostname.split('.').includes('thenorthface') ? window.location.hostname : "www.thenorthface.co.uk";

            fetch(
              `https://${domain}/webapp/wcs/stores/servlet/VFAjaxItemPricingView?requestype=ajax&storeId=${storeId}&langId=${urlLangId}&catalogId=${catalogId}&partNumber=${sku}&requesttype=ajax`
            )
              .then((res) => res.json())
              .then((prices) => {
                const colors = data.CatalogEntryView[0].Attributes?.find(
                  (attr) => attr.identifier === "COLOR_" + sku.toUpperCase()
                )?.Values.map((val) => ({
                  code: val.identifier,
                  label: val.values.toUpperCase(),
                }));

                const choiceVariantFromColors = colors.find(
                  ({ code }) =>
                    code ===
                    state.bundleConfiguration[
                      state.bundle.products[stepIndex].sku
                    ]?.variant
                );
                const variantFromColors = colors.find(
                  ({ code }) =>
                    code === state.bundle.products[stepIndex].variant
                );
                let fallbackVariantFromColors;
                if (state.bundle.data?.fallback_variants_availability?.[sku]) {
                  fallbackVariantFromColors = colors.find(
                    ({ code }) =>
                      code ===
                      state.bundle.data.fallback_variants_availability[sku]
                  );
                } else {
                  fallbackVariantFromColors = colors.find(
                    ({ code }) =>
                      code === state.bundle.data.fallback_variants[sku]
                  );
                }
                const variant =
                  choiceVariantFromColors ||
                  variantFromColors ||
                  fallbackVariantFromColors ||
                  colors[0];

                const serializedProduct =  serializeProductData(
                  data,
                  availability,
                  variant,
                  colors,
                  prices
                )

                productDetailView({
                  avgRating: serializedProduct.xcatentry_ProductRating,
                  bundleId: state.bundle.id,
                  colorCode: serializedProduct.variant.code,
                  colorDescription: serializedProduct.variant.label,
                  name: serializedProduct.name,
                  numReviews: serializedProduct.xcatentry_ProductRatingCount,
                  price: getDiscountPrice(serializedProduct.finalPrice, state.bundle.discount),
                  saleDiscountAmount: state.bundle.discount,
                  variant: serializedProduct.variant.code,
                  sku,
                  partNumber: Object.values(availability.partNumbers)[0]
                  .split(':').slice(0,2).join(':')
                }, state.bundle, stepIndex)

                dispatch({
                  type: actions.SET_STEP,
                  step: {
                    index: stepIndex,
                    product: serializedProduct
                  },
                });
              });
          }
        );
      });
    }
  }
}

export function setLoading(context) {
  const { dispatch } = context;
  dispatch({
    type: actions.SET_PDP_LOADING,
  });
}

export function getBundle(context, id, preview) {
  const { dispatch } = context;

  getPublicBundleList({ id, published: preview }).then((data) => {
    if (data.status === 200 && data.bundles.length > 0) {
      dispatch({
        type: actions.GET_BUNDLE,
        bundle: {
          ...data.bundles[0],
          products: JSON.parse(data.bundles[0].products),
          data: JSON.parse(data.bundles[0].data),
        },
      });
    }
  });
}

export function setVariant({ state, dispatch }, sku, variant) {
  setLoading({ dispatch });
  window.websphere.productViewByStyleNumber(sku, (data) => {
    window.websphere.productAvailabilityViewByProductId(
      data.CatalogEntryView[0].uniqueID,
      (availability) => {
        const urlLangId = document.querySelector('meta[name="langId"]')?.content || "-11";
        const storeId = document.querySelector('meta[name="storeId"]')?.content || "7005";
        const catalogId = document.querySelector('meta[name="catalogId"]')?.content || "13506";
        const domain = window.location.hostname.split('.').includes('thenorthface') ? window.location.hostname : "www.thenorthface.co.uk";

        fetch(
          `https://${domain}/webapp/wcs/stores/servlet/VFAjaxItemPricingView?requestype=ajax&storeId=${storeId}&langId=${urlLangId}&catalogId=${catalogId}&partNumber=${sku}&requesttype=ajax`
        )
          .then((res) => res.json())
          .then((prices) => {
            const colors = data.CatalogEntryView[0].Attributes?.find(
              (attr) => attr.identifier === "COLOR_" + sku.toUpperCase()
            )?.Values.map((val) => ({
              code: val.identifier,
              label: val.values.toUpperCase(),
            }));

            // Get serialized Size Data for new variant for keeping size
            const serializedData = serializeProductData(
              data,
              availability,
              variant,
              colors,
              prices
            );

            let size;
            // If there's inseam select size with inseam otherwise consider only size
            if (state.bundleConfiguration[sku]?.inseam) {
              size = serializedData.sizes.find(
                (item) =>
                  item.size === state.bundleConfiguration[sku]?.size &&
                  item.inseamKey === state.bundleConfiguration[sku]?.inseam
              );
            } else {
              size = serializedData.sizes.find(
                (item) => item.size === state.bundleConfiguration[sku]?.size
              );
            }
            const inseam = serializedData.hasInseam
              ? serializedData.inseam.find(
                  (item) =>
                    item.inseamKey === state.bundleConfiguration[sku]?.inseam
                )
              : null;

            // Autoselect One Size
            if (!size && serializedData.sizes.length === 1) {
              size = serializedData.sizes[0];
            }

            dispatch({
              type: actions.SET_CHOICE_VARIANT,
              product: sku,
              variant: variant.code,
              sizeData: size,
              inseamData: inseam,
              hasInseam: serializedData.hasInseam,
              stepProduct: serializedData,
            });
          });
      }
    );
  });
}

export function setSize({ state, dispatch }, size, catId, display, stockLeft) {
  dispatch({
    type: actions.SET_CHOICE_SIZE,
    product: state.step.product.partNumber,
    size: size,
    stockLeft: stockLeft,
    catId: catId,
    sizeLabel: display,
  });
}

export function setSizeAlert({ dispatch }, active) {
  dispatch({
    type: actions.SET_STEP_SIZE_ALERT,
    active,
  });
}

export async function addBundleToCart({ state, dispatch }) {
  dispatch({ type: actions.ADD_TO_CART });
  dispatch({ type: actions.SET_PDP_CART_LOADING });
  const products = Object.keys(state.bundleConfiguration).map(
    (sku) => state.bundleConfiguration[sku].catId
  );
  let result;
  let resultData = [];

  const asyncFunctions = products.map((id) => () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        window.websphere.bundleAddToCart(
          id,
          (data) => {
            resultData.push(data);
            resolve(true);
          },
          () => resolve(false)
        );
      }, 75);
    });
  });
  await asyncFunctions.reduce(async (previousPromise, nextAsyncFunction) => {
    await previousPromise;
    result = await nextAsyncFunction();
  }, Promise.resolve());

  if (result) {
    // DESKTOP
    window.websphere.updateMiniCart();

    addToCart(Object.keys(state.bundleConfiguration).map(key=>{
      return {
        sku: key,
        size: state.bundleConfiguration[key].size,
        name: state.bundleConfiguration[key].name,
        price: state.bundleConfiguration[key].price,
        colorDescription: state.bundleConfiguration[key].colorDescription,
        colorCode: state.bundleConfiguration[key].colorCode,
        bundleId: state.bundle.id,
        id: state.bundleConfiguration[key]?.partNumbers?.[state.bundleConfiguration[key]?.catId],
      }
    }), state.bundle, state.step.index);

    const mobileMiniCart = document.querySelector(
      ".topnav-minicart-notification.notification"
    );
    const mobileTopMiniCart = document.querySelector(".topnav-minicart-panel");
    if (mobileMiniCart && isMobile() && mobileTopMiniCart) {
      mobileMiniCart.innerHTML = populateMiniCartMobile(resultData);
      mobileTopMiniCart.classList.remove("hide");
      mobileMiniCart.classList.remove("hide");

      setTimeout(() => {
        mobileTopMiniCart.classList.add("hide");
        mobileMiniCart.classList.add("hide");
      }, 4500);
    }
    setTimeout(() => {
      dispatch({ type: actions.STOP_PDP_CART_LOADING });
      setTimeout(() => {
        dispatch({ type: actions.RESET_ADD_TO_CART_COMPLETE });
      }, 7000);
    }, 500);
  } else {
    dispatch({ type: actions.STOP_PDP_CART_LOADING });
  }
}

export function toggleSizesPopup({ dispatch }) {
  dispatch({ type: actions.TOGGLE_SIZES_POPUP });
}

export function setInseam({ state, dispatch }, inseam) {
  dispatch({
    type: actions.SET_CHOICE_INSEAM,
    product: state.step.product.partNumber,
    inseam: inseam,
  });
}

export function setStepBarMinimized({ dispatch }, state) {
  dispatch({
    type: actions.SET_STEP_BAR_MINIMIZED,
    minimized: state,
  });
}

export function toggleSliderGallery({ dispatch }) {
  dispatch({
    type: actions.TOGGLE_SLIDER_GALLERY,
  });
}

export function setMouseoverSize({dispatch}, size){
  dispatch({
    type: actions.SET_MOUSEOVER_SIZE,
    size
  });
}

export function removeMouseoverSize({dispatch}){
  dispatch({
    type: actions.REMOVE_MOUSEOVER_SIZE,
  });
}