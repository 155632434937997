import getSymbolFromCurrency from 'currency-symbol-map'
import LocaleCurrency from 'locale-currency'

export const getImageUrl = (sku, variant, imageType = 'hero') => {
    return `https://images.thenorthface.com/is/image/TheNorthFaceEU/${sku}_${variant}_${imageType}?$262x306$`
}

export const getDetailImageUrl = (image) => {
    const imageUrl = 'https://images.thenorthface.com/is/image/'
    return `${imageUrl}/${image}?wid=1250&hei=1250&fmt=jpg&qlt=85,1&op_sharpen=0&resMode=sharp2&op_usm=1,1,1,0`
}

export const getDetailZoomImageUrl = (image) => {
    const imageUrl = 'https://images.thenorthface.com/is/image/'
    return `${imageUrl}/${image}?wid=1250&hei=1250&fmt=jpg&qlt=85,1&op_sharpen=0&resMode=sharp2&op_usm=1,1,1,0`
}

export const getDiscountPrice = (price, discount) => {
    const discountNumber = Number(discount.replace('%', ''))
    let formattedPrice = price.toString().replace(getCurrency()+ '', '').replace(',','.').replace('&nbsp;', '').replace('zl', '').replace('kr', '').replace('K&#269;', '').replace(/\s/g, '').trim()
    if((formattedPrice.split('.').length -1) > 1) {
        formattedPrice = formattedPrice.replace('.', '')
    }
    return formattedPrice - (formattedPrice * (discountNumber / 100))
}

export function decodeEntities(encodedString) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
}

export function getStepBarProductVariant(product, selectedConfiguration, fallbackVariant, fallbackAvailability) {
    return selectedConfiguration[product.sku]?.variant ? selectedConfiguration[product.sku].variant : product.variant ? product.variant : fallbackAvailability[product.sku] ? fallbackAvailability[product.sku] : fallbackVariant[product.sku]
}

export function getListProductVariant(productVariant, fallbackVariant, availabilityVariant) {
    return availabilityVariant ? availabilityVariant : productVariant ? productVariant : fallbackVariant
}

export function getQueryParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params
}

export function mapRange(number, inMin, inMax, outMin, outMax) {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

export function getCurrency() {
  const locale = document.querySelector("#root").getAttribute("data-view-id");
  return getSymbolFromCurrency(LocaleCurrency.getCurrency(locale));
}

export function formatPrice(price) {
    return price.replace(',', '.').replace('&nbsp;', ' ').replace('K&#269;', `${getCurrency()} `)
}

export function formatPriceWithCurrency(price) {
    const locale = document.querySelector("#root").getAttribute("data-view-id");
    if(locale === 'sv_SE' || locale === 'pl_PL' || locale === 'en_SE'){
        return price.toFixed(2) + ' ' + getCurrency()
    } else {
        return getCurrency() + ' ' + price.toFixed(2)
    }
}

export function isMobile() {
    return window.screen.width < 1024;
}

export function isTestingEnv() {
  return window.location.hostname.indexOf("www.") < 0;
}