import { useContext } from 'react';

/* styles */
import sizeStyle from '@styles/pdp/SizePicker.module.scss';
import inseamStyle from '@styles/pdp/InseamPicker.module.scss';

/* context */
import context from '@context/pdp';
import { setInseam } from '@context/pdp/actions';

export default function InseamPicker() {
    const { state, dispatch } = useContext(context);
    const inseams = state.step.product.inseam

    const getClassName = (inseam) => {
        let className = `${sizeStyle.sizePickerItem} ${inseamStyle.inseamPickerItem}`

        if (inseam.inseamKey === state.bundleConfiguration[state.step.product.partNumber]?.inseam) {
            className += ` ${sizeStyle.sizePickerItemSelected} `;
        }

        if (!inseam.stock) {
            className += ` ${sizeStyle.sizePickerItemDisabled}`;
        }

        return className;
    }

    return <div className={inseamStyle.inseamPicker}>
        {inseams.map(item => {
            return <div key={item.inseam.toLowerCase()} className={getClassName(item)} onClick={() => setInseam({ state, dispatch }, item)}>
                {item.inseam}
            </div>
        })}
    </div>
}