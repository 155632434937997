import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import "../../i18n"

/* styles */
import style from "@styles/plp/BundleItem.module.scss"

/* utils */
import { onGoToBundle } from "@utils/analytics"
import { getImageUrl, getDiscountPrice, getListProductVariant, getCurrency } from "@utils/helpers"

export default function BundleItem({ bundle, preview, threeItemsLayout, forceNoImage, context }) {
  const { products, data } = bundle
  const { t } = useTranslation("translation")
  const detailUrl = "/bundle-detail/"

  let parsedProducts = []

  try {
    parsedProducts = typeof products === 'string' ? JSON.parse(products) : products
    if (!Array.isArray(parsedProducts)) {
      parsedProducts = []
    }
  } catch (error) {
    console.error("Error parsing products JSON:", error)
    parsedProducts = []
  }

  const getThumbsClassName = () => {
    let className = style.thumbs
    if (!threeItemsLayout) className += ' '
    className += ' ' + style.desktop
    return className
  }

  return (
    <div className={`${style.container} ${(!bundle.image || forceNoImage) ? style.noImage : ''}`}>
      <div className={style.productInfo}>
        <h2 className={style.name}>{bundle.name}</h2>
        <div className={getThumbsClassName()}>
          {parsedProducts.map((product, index) => (
            <Thumb key={index} data={{ index, product, data, bundleId: bundle.id, preview }} />
          ))}
        </div>
        <div className={style.info}>
          <div className={`${style.thumbs} ${style.mobile}`}>
            {parsedProducts.map((product, index) => (
              <Thumb key={index} data={{ index, product, data, bundleId: bundle.id, preview }} />
            ))}
          </div>
          {context === 'collection' ? (
            bundle.price && (
              <span className={style.fullPrice}>
                {getCurrency()} {bundle.price.toFixed(2)}
              </span>
            )
          ) : (
            bundle.discount > 0 && bundle.price ? (
              <>
                <span className={style.price}>
                  {getCurrency()} {getDiscountPrice(bundle.price, bundle.discount).toFixed(2)}
                </span>
                <span className={style.priceCrossed}>
                  {getCurrency()} {bundle.price.toFixed(2)}
                </span>
                <span className={style.discount}>
                  {t("plp.SAVE").replace('X', bundle.discount)}
                </span>
              </>
            ) : (
              bundle.price && (
                <span className={style.fullPrice}>
                  {getCurrency()} {bundle.price.toFixed(2)}
                </span>
              )
            )
          )}
        </div>
        <Link onClick={() => onGoToBundle(bundle.name, bundle.price, bundle.discount)} to={detailUrl + bundle.id + (preview ? '?preview=true' : '')} className={style.cta}>
          {context === 'collection' ? t("plp.SHOP-NOW") : t("plp.SHOP-BUNDLE")}
        </Link>
      </div>
      {bundle.image && !forceNoImage && (
        <div className={style.imageSection}>
          <img src={bundle.image} alt={t("plp.EDITORIAL-IMAGE")} className={style.promoImage} />
        </div>
      )}
    </div>
  )
}

function Thumb(props) {
  const { index, product, data, bundleId, preview } = props.data
  const { t } = useTranslation("translation")
  const detailUrl = `/bundle-detail/${bundleId}?step=${index}`

  const variant = getListProductVariant(product.variant, product.fallback, data?.fallback_variants?.[product.sku])

  const firstImg = {
    sku: product.sku,
    variant,
    type: product.image1type || 'hero'
  }
  const secondImg = {
    sku: product.sku,
    variant,
    type: product.image2type || 'alt1'
  }

  return (
    <div className={style.thumbWrapper}>
      <Link to={detailUrl + (preview ? '&preview=true' : '')} onClick={() => onGoToBundle(product.name, product.price, product.discount)}>
        <img
          alt={product.sku}
          className={`${style.thumbImage} ${style.thumbImageDefault}`}
          src={getImageUrl(firstImg.sku, firstImg.variant, firstImg.type)}
        />
      </Link>
      <Link to={detailUrl + (preview ? '&preview=true' : '')} onClick={() => onGoToBundle(product.name, product.price, product.discount)}>
        <img
          alt={product.sku}
          className={`${style.thumbImage} ${style.thumbImageAlt}`}
          src={getImageUrl(secondImg.sku, secondImg.variant, secondImg.type)}
        />
      </Link>
    </div>
  )
}