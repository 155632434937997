import { useContext, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import "../../i18n"

/* styles */
import style from '@styles/plp/Filters.module.scss'

/* context */
import context from "@context/plp"
import { toggleFilter, clearAllFilters, setSorting, setFilterLoading } from "@context/plp/actions"

/* utils */
import { updateURLWithFilters } from "@utils/listing"
import { onApplyFilter } from "@utils/analytics"

/* components */
import Dropdown from '../generic/Dropdown'
import ActiveFilter from '../generic/ActiveFilter'

/* config */
import { sortOptions, getTranslations } from '@utils/const'

export default function Filters({ hideSortingDropdown }) {
  const { state, dispatch } = useContext(context)
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const location = useLocation()
  
  const [selectedSortOption, setSelectedSortOption] = useState(0)
  const [activeFilter, setActiveFilter] = useState(null)
  const [showFilters, setShowFilters] = useState(false)
  const [hasActiveFilters, setHasActiveFilters] = useState(false)

  const sortOptionsConst = sortOptions(t)
  const translationsConst = getTranslations(t)

  useEffect(() => {
    setHasActiveFilters(Object.keys(state.filters.active).length > 0)
  }, [state.filters.active])

  const handleSort = (sortOptionId) => {
    setSelectedSortOption(sortOptionId)
    setSorting({ dispatch, state }, sortOptionId)
  }

  const handleFilterSelect = (property, value) => {
    onApplyFilter(value)
    toggleFilter({ state, dispatch }, { property, value }, t)
    
    const updatedFilters = {
      ...state.filters.active,
      [property]: state.filters.active[property]
        ? state.filters.active[property].includes(value)
          ? state.filters.active[property].filter(v => v !== value)
          : [...state.filters.active[property], value]
        : [value]
    }

    // Concatenate unisex results with men/women
    if (property === 'gender') {
      const genderFilters = updatedFilters.gender || []
      const lowerCaseGenderFilters = genderFilters.map(g => g.toLowerCase())
      if (lowerCaseGenderFilters.includes('men') || lowerCaseGenderFilters.includes('women')) {
        updatedFilters.gender = [...lowerCaseGenderFilters, 'unisex']
          .filter((v, i, a) => a.indexOf(v) === i)
      }
    }

    setFilterLoading({ dispatch }, false)
    updateURLWithFilters(updatedFilters, navigate)
    setActiveFilter(null)
    setShowFilters(false)
  }

  const handleRemove = (property, value) => {
    toggleFilter({ state, dispatch }, { property, value }, t)
    
    const updatedFilters = {
      ...state.filters.active,
      [property]: state.filters.active[property].filter(v => v !== value)
    }

    if (updatedFilters[property].length === 0) {
      delete updatedFilters[property]
    }

    setFilterLoading({ dispatch }, false)
    updateURLWithFilters(updatedFilters, navigate)
  }

  const handleFilterExpand = (property) => {
    setActiveFilter(activeFilter === property ? null : property)
  }
  
  const translate = (key, option = null) => {
    const translations = translationsConst
    if (option) {
      return translations[key]?.options?.[option.toLowerCase()] || option;
    }
    return translations[key]?.label || key;
  };

  const selectedSortLabel = sortOptionsConst.find(option => option.id === selectedSortOption)?.label

  return (
    <div className={style.container}>
      {/* Versione Desktop */}
      <div className={`${style.filters} ${style.desktopFilters}`}>
        {Object.keys(state.filters.all).map((key) => (
          state.filters.all[key].length > 0 && (
            <div key={key} className={style.filter}>
              <button 
                className={`${style.filterButton} ${activeFilter === key ? style.active : ''}`} 
                onClick={() => handleFilterExpand(key)}>
                {translate(key)}
                <span className={`${style.caret} ${activeFilter === key ? style.active : ''}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="13" height="13">
                    <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/>
                  </svg>
                </span>
              </button>
              {activeFilter === key && (
                <div className={style.filterOptions}>
                  {state.filters.all[key].map((option, index) => {
                    const isUnisexHidden = (key === 'gender' && 
                      (state.filters.active.gender?.includes('men') || 
                      state.filters.active.gender?.includes('women')) && 
                      option.toLowerCase() === 'unisex')

                    if (isUnisexHidden) {
                      return null // Do not render the "Unisex" option
                    }

                    const isActive = state.filters.active[key]?.includes(option.toLowerCase())
                    return (
                      <div key={index} className={style.filterOptionContainer}>
                        <button
                          className={`${style.filterOption} ${isActive ? style.active : ''}`}
                          onClick={!isActive ? () => handleFilterSelect(key, option) : undefined}
                        >
                          {translate(key, option)}
                            {isActive && (
                            <button
                              className={style.removeFilterButton}
                              onClick={() => handleRemove(key, option.toLowerCase())}
                            >
                              &times;
                            </button>
                          )}
                        </button>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )
        ))}
      </div>

      {/* Versione Mobile */}
      <div className={`${style.mobileFilters} ${showFilters ? style.active : ''}`}>
        {Object.keys(state.filters.all).length > 0 && (
          <button 
            className={`${style.filterButton} ${hasActiveFilters ? style.active : ''}`} 
            onClick={() => setShowFilters(!showFilters)}>
            {t("plp.FILTERS.FILTERS")}
            <span className={`${style.caret} ${showFilters ? style.active : ''}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="13" height="13">
                <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/>
              </svg>
            </span>
          </button>
        )}
        {showFilters && (
          <div className={style.filterModal}>
            {Object.keys(state.filters.all).map((key) => (
              state.filters.all[key].length > 0 && (
                <div key={key} className={style.filter}>
                  <button 
                    className={`${style.filterButton} ${activeFilter === key ? style.active : ''}`} 
                    onClick={() => handleFilterExpand(key)}>
                    {translate(key)}
                    <span className={`${style.caret} ${activeFilter === key ? style.active : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="13" height="13">
                      <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/>
                    </svg>
                    </span>
                  </button>
                  {activeFilter === key && (
                    <div className={style.filterOptions}>
                      {state.filters.all[key].map((option, index) => {
                        const isUnisexHidden = (key === 'gender' && 
                          (state.filters.active.gender?.includes('men') || 
                          state.filters.active.gender?.includes('women')) && 
                          option.toLowerCase() === 'unisex')
    
                        if (isUnisexHidden) {
                          return null // Do not render the "Unisex" option
                        }

                        const isActive = state.filters.active[key]?.includes(option.toLowerCase())
                        return (
                          <div key={index} className={style.filterOptionContainer}>
                            <button
                              className={`${style.filterOption} ${isActive ? style.active : ''}`}
                              onClick={!isActive ? () => handleFilterSelect(key, option) : undefined}
                            >
                              {translate(key, option)}
                                {isActive && (
                                <button
                                  className={style.removeFilterButton}
                                  onClick={() => handleRemove(key, option.toLowerCase())}
                                >
                                  &times;
                                </button>
                              )}
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            ))}
          </div>
        )}
      </div>

      {!hideSortingDropdown && (
        <div className={style.sort}>
          <Dropdown 
            label={selectedSortLabel} 
            options={sortOptionsConst} 
            onSelect={handleSort} 
          />
        </div>
      )}

      {Object.keys(state.filters.active).length > 0 && (
        <div className={style.activeFilters}>
          <div>
            {Object.entries(state.filters.active).flatMap(([property, values]) =>
              values.map((value, index) => (
                <ActiveFilter
                  key={`${property}-${index}`}
                  property={property}
                  value={value}
                  state={state}
                  dispatch={dispatch}
                  t={t}
                />
              ))
            )}
            <button className={style.clearAll} onClick={() => {
              clearAllFilters({ dispatch })
              navigate(location.pathname)
            }}>
              {t("plp.FILTERS.CLEAR-ALL")}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}