import { useEffect, useState, useContext } from "react";
import { ReactComponent as Close } from "@svg/close.svg";

/* styles */
import style from "@styles/pdp/SizesChartModal.module.scss";

/* context */
import context from "@context/pdp";

/* utils */
import { getSizeChart } from "@utils/detail";
import { toggleSizesPopup } from "@context/pdp/actions";

export default function SizesChartModal() {
  const [html, setHtml] = useState();
  const { state, dispatch } = useContext(context);

  const handleKeyEsc = (event) => {
    if (event.key === "Escape") {
      toggleSizesPopup({ dispatch });
    }
  };

  useEffect(() => {
    getSizeChart(state.step.product.sizeContext).then((response) => {
      setHtml(response);
      setTimeout(() => {
        const script = document.querySelector(
          "#root > div > div.b-container > div > div.SizesChartModal_overlay__8sKiq > div > div > div > div:nth-child(3) > script:nth-child(2)"
        );
        // eslint-disable-next-line no-new-func
        const myScript = new Function(script.text);
        myScript();
      }, 500);
    });

    document.addEventListener("keydown", handleKeyEsc, false);

    return () => {
      document.removeEventListener("keydown", handleKeyEsc, false);
    };
  }, []);

  return (
    <div className={style.overlay}>
      <div className={style.container}>
        <button
          className={style.close}
          onClick={() => toggleSizesPopup({ dispatch })}
        >
          <Close />
        </button>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
    </div>
  );
}
