import { useContext } from 'react';

/* styles */
import style from '@styles/pdp/SizePicker.module.scss';

/* context */
import context from '@context/pdp';
import { setSize, setMouseoverSize, removeMouseoverSize } from '@context/pdp/actions';

/* utils */
import { sortSizes } from '@utils/detail';
import { useSizeInseamTranslation } from '@utils/use-size-translation';

export default function SizePicker() {
    const { state, dispatch } = useContext(context);

    const GetLabel = (value) => {
        return useSizeInseamTranslation(value)
    }

    const getClassName = (sizesList) => {
        let className = style.sizePicker;
        if(sizesList.filter(s=> s.size.length > 4 && s.sizeKey !== 'OS').length === sizesList.length)
            className += ` ${style.sizePickerYears}`
        return className
    }
    const getItemClassName = (size, sizeKey, stock) => {
        let className = style.sizePickerItem;

        if (size === state.bundleConfiguration[state.step.product.partNumber]?.size) {
            className += ` ${style.sizePickerItemSelected} `;
            className += ` ${style.sizePickerItemSelectedNoHover}`;
        }
        if (!stock) {
            className += ` ${style.sizePickerItemDisabled}`;
        }
        if(sizeKey !== 'OS') {
            className += ` ${style.sizePickerItemFixed}`;
        }
        return className;
    }

    const sizesList = state.step.product.hasInseam ? state.step.product.sizes
    .filter((size) => size.inseamKey === (state.bundleConfiguration[state.step.product.partNumber]?.inseam ? state.bundleConfiguration[state.step.product.partNumber]?.inseam : state.step.product.sizes[0].inseamKey))
    : state.step.product.sizes

    return <div className={getClassName(sizesList)}>
        {sortSizes(sizesList).map(({ size, sizeKey, display, stock, catId, stockLeft }) => <div 
            key={size} 
            className={getItemClassName(size, sizeKey, stock)} 
            onClick={() => setSize({ state, dispatch }, size, catId, display, stockLeft)}
            onMouseEnter={()=>setMouseoverSize({dispatch}, {stock, size, sizeKey, catId, stockLeft})}
            onMouseLeave={()=>removeMouseoverSize({dispatch})}
            >
            {GetLabel(size)}
        </div>)}
    </div>
}