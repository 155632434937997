export const getPublicBundleList = async (params = {}) => {
    params.view_id = document.getElementById("root").dataset.viewId
    params.orderBy = 'cross_order'
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
    let res = await fetch(`${process.env.REACT_APP_API_URL}/bundles?${query}`)
    let data = await res.json()
    data.status = res.status
    return data
}

export const getPublicBundleGroupList = async (params = {}) => {
    params.view_id = document.getElementById("root").dataset.viewId
    params.orderBy = 'cross_order'
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
    let res = await fetch(`${process.env.REACT_APP_API_URL}/bundle-group?${query}`)
    let data = await res.json()
    data.status = res.status
    return data
}

export const getPublicCollectionGroupList = async (params = {}) => {
    params.view_id = document.getElementById("root").dataset.viewId
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
    let res = await fetch(`${process.env.REACT_APP_API_URL}/collection-group?${query}`)
    let data = await res.json()
    data.status = res.status
    return data
}