import { useState } from "react"
import style from '@styles/generic/Dropdown.module.scss'

export default function Dropdown({ label, options, onSelect }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (id) => {
    onSelect(id)
    setIsOpen(false)
  }

  return (
    <div className={style.dropdown}>
        <button
            className={`${style.dropdownButton} ${isOpen ? style.active : ""}`}
            onClick={() => setIsOpen(!isOpen)}
        >
            {label}
            <span className={`${style.caret}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="13" height="13">
                    <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/>
                </svg>
            </span>
        </button>
        <div className={`${style.dropdownContent} ${isOpen ? style.show : ""}`}>
            {options.map((option) => (
            <button
                key={option.id}
                className={style.dropdownItem}
                onClick={() => handleSelect(option.id)}
            >
                {option.label}
            </button>
            ))}
        </div>
    </div>
  )
}