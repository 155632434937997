import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import "../../i18n"

/* styles */
import style from "@styles/plp/BundleItem.module.scss"

/* utils */
import BundleItem from "./BundleItem"

export default function BundleGroupItem({ bundleGroup, bundle, preview, context }) {
  const { t, i18n } = useTranslation("translation")
  let bundles = []

  if (context === 'collection') {
    bundles = Array.isArray(bundleGroup.bundles) ? bundleGroup.bundles : JSON.parse(bundleGroup.bundles || '[]')
  } else if (context === 'listing') {
    bundles = bundle ? [bundle] : (Array.isArray(bundleGroup.bundles) ? bundleGroup.bundles : JSON.parse(bundleGroup.bundles || '[]'))
  }

  const imageRef = useRef(null)
  const infoRef = useRef(null)
  const containerRef = useRef(null)
  const titleRef = useRef(null)
  const [position, setPosition] = useState()
  const [top, setTop] = useState()
  const [titlePosition, setTitlePosition] = useState('static')
  const [titleWidth, setTitleWidth] = useState()
  const [titleHeight, setTitleHeight] = useState('auto')
  const [titleTop, setTitleTop] = useState('0')
  const [lastScrollY, setLastScrollY] = useState(0)
  const [isImageVisible, setIsImageVisible] = useState(true)

  const currentLanguage = i18n.language

  useEffect(() => {
    const handleScroll = async () => {
      if (!imageRef.current || !infoRef.current || !containerRef.current || !titleRef.current) return

      const imageRect = imageRef.current.getBoundingClientRect()
      const infoRect = infoRef.current.getBoundingClientRect()
      const containerRect = containerRef.current.getBoundingClientRect()
      const titleRect = titleRef.current.getBoundingClientRect()
      const currentScrollY = window.scrollY

      const isScrollingDown = currentScrollY > lastScrollY
      const isScrollingUp = currentScrollY < lastScrollY

      const isLandscape = imageRef.current.parentElement.classList.contains(style.imgLandscape)

      if (isLandscape) {
        if (containerRect.top >= 550) {
          setPosition('absolute')
          setTop('-550px')
          imageRef.current.style.width = '100%'
          setIsImageVisible(true)
        } else if (isScrollingDown && imageRect.top >= infoRect.top + 80) {
          setIsImageVisible(false)
        } else if (isScrollingDown && imageRect.top <= 0 && imageRect.bottom < infoRect.bottom) {
          setPosition('fixed')
          setTop('0')
          imageRef.current.style.width = `${containerRect.width}px`
          setIsImageVisible(true)
        } else if (isScrollingUp && imageRect.top === 0 && imageRect.bottom > infoRect.bottom) {
          setPosition('fixed')
          setTop('0')
          imageRef.current.style.width = `${containerRect.width}px`
        } else if (isScrollingUp && imageRect.top <= infoRect.top + 80) {
          setIsImageVisible(true)
        }

        if (isScrollingUp && titleRect.top >= containerRect.top) {
          setTitlePosition('static')
          setTitleTop('initial')
          setTitleHeight('100vh')
        } else if (isScrollingDown && titleRect.top <= 0 && titleRect.bottom < containerRect.bottom) {
          setTitlePosition('fixed')
          setTitleTop('0')
          setTitleHeight('auto')
          setTitleWidth(`${titleRect.width}px`)
        } else if (isScrollingUp && titleRect.top > 0 && titleRect.bottom > infoRect.bottom) {
          console.log(titleRect.top, containerRect.top)
          setTitlePosition('fixed')
          setTitleTop('0')
          setTitleWidth(`${titleRect.width}px`)
        } else if (titleRect.bottom >= infoRect.bottom) {
          setTitlePosition('absolute')
          setTitleTop(`${containerRect.height - titleRect.height}px`)
          setTitleHeight('auto')
        }
      } else {
        if (containerRect.top >= 150) {
          setPosition('absolute')
          setTop('-150px')
        } else if (isScrollingDown && imageRect.top <= 0 && imageRect.bottom < infoRect.bottom) {
          setPosition('fixed')
          setTop('0')
        } else if (isScrollingUp && imageRect.top > 0 && imageRect.bottom > infoRect.bottom) {
          setPosition('fixed')
          setTop('0')
        } else if (imageRect.bottom >= infoRect.bottom + 150) {
          setPosition('absolute')
          setTop(`${infoRect.height - imageRect.height + 150}px`)
        }
      }

      setLastScrollY(currentScrollY)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [lastScrollY])

  const imageStyleMapping = {
    left: style.imgLeft,
    right: style.imgRight,
    landscape: style.imgLandscape
  }
  const imageClass = bundleGroup.image && bundleGroup.image.style ? imageStyleMapping[bundleGroup.image.style] : ''

  return (
    <div className={`${style.container} ${style.bundleGroup} ${imageClass}`} ref={containerRef}>
      {bundleGroup.image && (
        <div className={`${style.imageSection} ${!isImageVisible ? style.hiddenLandscapeImage : ''}`} ref={imageRef} style={{ position, top }}>
          <img src={bundleGroup.image.url} alt={t("plp.EDITORIAL-IMAGE")} className={style.promoImage} />
        </div>
      )}
      <div className={style.productInfo} ref={infoRef}>
        <div ref={titleRef} style={{ position: titlePosition, top: titleTop, width: titleWidth, height: titleHeight }}>
          {(bundleGroup.tag || bundleGroup.associated) && (
            <span className={style.bundleGroupTag}>
              {bundleGroup.tag ? bundleGroup.tag : bundleGroup.associated}
            </span>
          )}
          <h2 className={style.bundleGroupTitle}>{bundleGroup.title}</h2>
          <p className={style.bundleGroupDesc}>{bundleGroup.description}</p>
          {bundleGroup.cta && (
            <Link to={bundleGroup.cta.url} target={bundleGroup.cta.target} className={`${style.cta} ${style.ctaSecondary}`}>
              {t("plp.DISCOVER-MORE")}
            </Link>
          )}
        </div>
        <div className={style.bundleGroupBundles}>
          {bundles.map(bundle => {
            const currentLanguage = i18n.language;
            const fullLanguageCode = Object.keys(bundle.translations).find(lang => lang.startsWith(currentLanguage));
            const localizedBundleName = bundle.translations && bundle.translations[fullLanguageCode]
              ? bundle.translations[fullLanguageCode]
              : bundle.translations[currentLanguage]
                ? bundle.translations[currentLanguage]
                : bundle.name;

            return (
              <BundleItem
                key={bundle.id}
                bundle={{ ...bundle, name: localizedBundleName }}
                preview={preview}
                threeItemsLayout={true}
                forceNoImage={true}
                context={context}
              />
            );
          })}
        </div>
      </div>
    </div>
  )
}