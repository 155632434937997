/* styles */
import style from '@styles/pdp/Price.module.scss';

/* utils */
import { formatPriceWithCurrency } from "@utils/helpers";

export default function Price ({listPrice, offerPrice}){
    const isDiscounted = listPrice !== offerPrice && offerPrice < listPrice
    return <div className={style.priceContainer}>
        {isDiscounted ? (
            <>
                <span className={style.priceCrossed}>{formatPriceWithCurrency(listPrice)}</span>
                <span className={style.priceSale}>{formatPriceWithCurrency(offerPrice)}</span>
            </>
        ) : (
            <span className={style.price}>{formatPriceWithCurrency(listPrice)}</span>
        )}
    </div>

}