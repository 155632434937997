/* styles */
import style from "@styles/pdp/SizeAlert.module.scss"
import { useTranslation } from "react-i18next";

export default function SizeAlert() {
    const { t } = useTranslation("translation");

    return <div className={style.container}>
        <span className={style.icon}></span>
        <span className={style.label}>{t('pdp.PLEASE-SELECT-SIZE')}</span>
        <div className={style.arrowContainer}>
            <div className={style.arrowWrapper}>
                <div className={style.arrowBorder}>
                    <div className={style.arrowInner}></div>
                </div>
            </div>
        </div>
    </div>
}