export function populateMiniCartMobile(resultData) {
  const storeId = resultData[0]?.storeId;
  const orderId = resultData[0]?.orderId;
  const langId = resultData[0]?.langId;

  return `<div class="notification-text minicart-add-to-cart-message-container">
    <h2 class="minicart-add-to-cart-generic-message-text">
      <span class="icon-check"></span>
      ${window.globalThis?.miniCartTranslation["item-added"]}
    </h2>
    <p class="shopping-cart-text minicart-add-to-cart-generic-message-text-2"></p>
  </div>

  <div class="mini-cart-action-view-checkout-container">
    <a
      href="/shop/VFShippingAddressView?orderId=${orderId}&amp;storeId=${storeId}"
      class="mini-cart-action-view-checkout button primary small-12 columns"
    >
    ${window.globalThis?.miniCartTranslation["proceed-to-checkout"]}
    </a>
  </div>

  <div class="mini-cart-action-view-checkout-container">
    <a
      href="/shop/OrderCalculate?calculationUsageId=-1&amp;calculationUsageId=-2&amp;calculationUsageId=-7&amp;orderId=.&amp;storeId=${storeId}&amp;langId=${langId}&amp;URL=OrderItemDisplay%3FlangId%3D-11%26storeId%3D${storeId}%26deleteOutOfStockOrderItem%3DY%26orderId%3D."
      class="mini-cart-action-view-checkout button tertiary small-12 columns"
    >
    ${window.globalThis?.miniCartTranslation["view-basket"]}
    </a>
  </div>
  <div class="mini-cart-action-view-checkout-container">
    <a
      href="javascript:void(0);"
      class="small-12 text-center continue-shopping-link columns"
    >
    ${window.globalThis?.miniCartTranslation["continue-shopping"]}
    </a>
  </div>`;
}
